<template>
<div class="col-md-12">
      <div class="text-center text-main">
            <h1>Logga in</h1>
      </div>
      <div class="card card-container">
            <div v-if="display == 2">
                  <b-list-group v-if="renderusers">
                        <b-list-group-item button v-for="user in allusers" :key="user.email" @click="SetLatest(user)">
                              <b-row class="d-flex align-items-center">
                                    <b-col cols="12" md="3">
                                          <b-img class="login-avatar" center rounded="circle" :src="uavatar(user)"></b-img>
                                    </b-col>
                                    <b-col cols="10" md="7" class="text-primary">
                                          {{user.email}}
                                    </b-col>
                                    <b-col cols="2" @click.prevent.stop="RemoveLatest(user)">
                                          <inline-svg class="text-danger" width="20px" height="20px" :src="svg('alert/x-circle.svg')"></inline-svg>
                                    </b-col>
                              </b-row>
                        </b-list-group-item>
                  </b-list-group>
                  <div class="mt-4 text-center">
                        <h4><a class="link-primary pointer" @click="DisplayNone()">Annan användare</a></h4>
                  </div>
            </div>
            <form v-else name="form" @submit.prevent="handleLogin">
                  <div v-if="users.latest">
                        <div class="text-center">
                              <b-img class="login-avatar" center rounded="circle" :src="avatar()"></b-img>
                              <div class="mt-2 mb-2 font-large">
                                    {{users.latest.name}}</div>
                              (<a class="mt-2 link-primary pointer" @click="DisplayAllUsers()">Byt användare</a>)
                              <div class="d-none">
                                    <input v-model="user.email" v-validate="'required'" type="email" class="form-control" name="email" />
                              </div>
                        </div>
                  </div>
                  <div v-else>
                        <div class="text-center" v-if="display == 1 && allusers.length > 0">
                              <h4><a class="link-primary pointer" @click="DisplayAllUsers()">Välj bland tidigare inloggningar</a></h4>
                        </div>
                        <div class="form-group">
                              <label for="email">E-postadress</label>
                              <input v-model="user.email" v-validate="'required'" type="email" class="form-control" name="email" />
                              <div v-if="errors.has('email')" class="alert alert-danger mt-1" role="alert">Ange en e-postadress!</div>
                        </div>
                  </div>
                  <div class="form-group">
                        <label for="password">Lösenord</label>
                        <input v-model="user.password" v-validate="'required'" type="password" class="form-control" name="password" />
                        <div v-if="errors.has('password')" class="alert alert-danger mt-1" role="alert">Ange ett lösenord!</div>
                  </div>
                  <div class="form-group">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                              <span>Logga in</span>
                        </button>
                  </div>
                  <div class="form-group">
                        <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
                  </div>
                  <b-row>
                        <b-col md="6">
                              <router-link class="btn btn-secondary w-100" v-bind:to="{ path: '/glomtlosenord'}">Glömt lösenord</router-link>
                        </b-col>
                        <b-col md="6">
                              <router-link class="btn btn-secondary w-100 mt-1 mt-md-0" v-bind:to="{ path: '/registrera'}">Skapa konto</router-link>
                        </b-col>
                  </b-row>
            </form>
      </div>

      <div class="card card-container">
            <Loader v-if="tournamentsloading" loadingtext="..."></Loader>
            <div v-else>
                  <div class="mb-4">
                        <h3 class="text-center mb-4">Aktiva turneringar</h3>
                        <div v-if="activetournaments.length>0">
                             <TournamentFront v-for="tournament in activetournaments" :key="tournament.id" :tournament="tournament"></TournamentFront>
                        </div>
                        <Alert v-else variant="info" >Just nu finns inga aktiva turneringar</Alert>
                  </div>

                  <div class="mt-4">
                        <h3 class="text-center mb-4">Kommande turneringar</h3>
                        <div v-if="commingtournaments.length>0">
                             <TournamentFront v-for="tournament in commingtournaments" :key="tournament.id" :tournament="tournament"></TournamentFront>
                        </div>
                        <Alert v-else variant="info" >Just nu finns inga kommande turneringar</Alert>
                  </div>
                  
            </div>
      </div>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const TournamentFront = () => import("@/components/tournaments/TournamentFront");

export default {
      name: 'Login',
      components: {
            Alert,
            Loader,
            TournamentFront
      },
      data() {
            return {
                  user: {
                        username: "",
                        email: "",
                        password: ""
                  },
                  loading: false,
                  message: '',
                  display: 1,
                  renderusers: true
            };
      },
      metaInfo: {
            title: 'Logga in',
            meta: [{
                  name: 'description',
                  content: "Är du medlem på dflag-tipset loggar du in för att komma åt dina uppgifter och få tillgång till alla turneringar!"
            }]
      },
      computed: {
            loggedIn() {
                  return this.$store.state.auth.status.loggedIn;
            },
            users() {
                   var users = JSON.parse(localStorage.getItem("savedusers"));
                  if (!users)
                        return {
                              latest: false,
                              all: {}
                        };
                  return users;
            },
            tournamentsloading() {
                  return this.$store.getters["tournamentsg/tournamentsloading"];
            },
            activetournaments() {
                  var items = this.$store.getters["tournamentsg/tournaments"];
                  if (!items)
                        items = [];
                  return items.filter((item) => {
                        return item.status == 2 && item.active == 1;
                  });
            },           
            commingtournaments() {
                  var items = this.$store.getters["tournamentsg/tournaments"];
                  if (!items)
                        items = [];
                  return items.filter((item) => {
                        return item.status == 2 && item.active == 0;
                  });
            },
            allusers() {
                  var all = [];
                  var used = {};
                  for (var id in this.users.all) {
                        if (used[id])
                              continue;
                        var u = this.users.all[id];
                        all.push(u);
                        used[u.email] = u;
                  }

                  return all;
            }
      },
      created() {
            if (this.loggedIn) {
                  this.$router.push('/');
            } else {
                  if (this.users.latest)
                        this.user.email = this.users.latest.email;
            }
      },
      methods: {
            handleLogin() {
                  this.loading = true;
                  this.$validator.validateAll().then(isValid => {
                        if (!isValid) {
                              this.loading = false;
                              return;
                        }

                        if (this.user.email && this.user.password) {
                              this.$store.dispatch('auth/login', this.user).then(
                                    () => {
                                          var redirect = this.$route.query["redirect"];
                                          if (redirect)
                                                this.$router.push(redirect);
                                          else
                                                this.$router.push('/');
                                    },
                                    error => {
                                          this.loading = false;
                                          this.message =
                                                (error.response && error.response.data) ||
                                                error.message ||
                                                error.toString();
                                    }
                              );
                        }
                  });
            },            
            hasavatar() {
                  if (this.users.latest && this.users.latest.id)
                        return true;
                  return false;
            },
            avatar() {
                  return this.uavatar(this.users.latest);
            },
            uavatar(user) {
                  return this.$images.userAvatar(user.id);
            },
            DisplayAllUsers() {
                  this.display = 2;
            },
            DisplayNone() {
                  var users = this.users;
                  users.latest = false;
                  this.user.email = '';
                  this.user.password = '';
                  localStorage.setItem("savedusers", JSON.stringify(users));
                  this.display = 1;
            },
            SetLatest(user) {
                  var users = this.users;
                  users.latest = user;
                  this.user.email = user.email;
                  this.user.password = '';
                  localStorage.setItem("savedusers", JSON.stringify(users));
                  this.display = 1;
            },
            RemoveLatest(user){
                  this.renderusers = false;
                  var users = this.users;
                  delete users.all[user.email];
                  localStorage.setItem("savedusers", JSON.stringify(users));                  
                  var self = this;
                  setTimeout(function(){
                        self.renderusers = true;
                  }, 250);                  
            },
            svg(icon)
            {
                  return this.$images.svg(icon);
            }
      },
      mounted() {
            this.$store.dispatch("tournamentsg/getTournaments");
      }
};
</script>
